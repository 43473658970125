@import "base";

* {
  box-sizing: border-box;
  -webkit-font-smoothing: subpixel-antialiased; /* for keeping the same font-smoothing when using transform on WebKit (macOS and iOS) */
}

html, body {
  .font-default();
  margin: 0;
  padding: 0;
  color: @color-primary;
}

a {
  color: inherit;
  text-decoration: none;
  transition: color 300ms;
}

p a,
label a {
  color: @blue;

  &:hover {
    color: tint(@blue, 30%);
  }
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

img.emoji {
  height: 1em;
  width: 1em;
  margin: 0 .05em 0 .1em;
  vertical-align: -0.1em;
}

.pointer {
  *,
  *:before,
  *:after,
  &,
  &:before,
  &:after {
    cursor: pointer;
  }
}

.green-button {
  .font-default();
  .pointer();
  display: inline-block;
  font-size: 16px;
  color: white;
  background: @green;
  height: 50px;
  line-height: 50px;
  padding: 0 50px;
  border-radius: 25px;

  transition: background-color 300ms;

  &:hover {
    background: tint(@green, 20%);
  }

  &[disabled] {
    background: @color-secondary;
    cursor: not-allowed;
  }
}

.green-button-rect {
  .green-button();
  font-size: 14px;
  border-radius: 5px;
  padding: 0 30px;
  height: 40px;
  line-height: 40px;
}

textarea, input, button, select {
  /* fix font inside text fields for Firefox */
  font-family: inherit;
  font-size: inherit;

  &::placeholder {
    color: @color-secondary;
    opacity: 1;
  }
}

.closeX {
  .pointer();
  position: absolute;
  top: 10px;
  right: 12px;

  width: 26px;
  height: 26px;

  &:before, &:after {
    position: absolute;
    left: 12px;
    top: 4px;
    content: ' ';
    height: 20px;
    width: 2px;
    background-color: @color-primary;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}

.mdl-shadow--2dp {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.mdl-shadow--3dp {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
}
