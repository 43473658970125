* {
  box-sizing: border-box;
  -webkit-font-smoothing: subpixel-antialiased;
  /* for keeping the same font-smoothing when using transform on WebKit (macOS and iOS) */
}
html,
body {
  font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-weight: normal;
  margin: 0;
  padding: 0;
  color: #333333;
}
a {
  color: inherit;
  text-decoration: none;
  transition: color 300ms;
}
p a,
label a {
  color: #0095FF;
}
p a:hover,
label a:hover {
  color: #4db5ff;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
img.emoji {
  height: 1em;
  width: 1em;
  margin: 0 0.05em 0 0.1em;
  vertical-align: -0.1em;
}
.pointer *,
.pointer *:before,
.pointer *:after,
.pointer,
.pointer:before,
.pointer:after {
  cursor: pointer;
}
.green-button {
  font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-weight: normal;
  display: inline-block;
  font-size: 16px;
  color: white;
  background: #29AA00;
  height: 50px;
  line-height: 50px;
  padding: 0 50px;
  border-radius: 25px;
  transition: background-color 300ms;
}
.green-button *,
.green-button *:before,
.green-button *:after,
.green-button,
.green-button:before,
.green-button:after {
  cursor: pointer;
}
.green-button:hover {
  background: #54bb33;
}
.green-button[disabled] {
  background: #aaaaaa;
  cursor: not-allowed;
}
.green-button-rect {
  font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-weight: normal;
  display: inline-block;
  font-size: 16px;
  color: white;
  background: #29AA00;
  height: 50px;
  line-height: 50px;
  padding: 0 50px;
  border-radius: 25px;
  transition: background-color 300ms;
  font-size: 14px;
  border-radius: 5px;
  padding: 0 30px;
  height: 40px;
  line-height: 40px;
}
.green-button-rect *,
.green-button-rect *:before,
.green-button-rect *:after,
.green-button-rect,
.green-button-rect:before,
.green-button-rect:after {
  cursor: pointer;
}
.green-button-rect:hover {
  background: #54bb33;
}
.green-button-rect[disabled] {
  background: #aaaaaa;
  cursor: not-allowed;
}
textarea,
input,
button,
select {
  /* fix font inside text fields for Firefox */
  font-family: inherit;
  font-size: inherit;
}
textarea::placeholder,
input::placeholder,
button::placeholder,
select::placeholder {
  color: #aaaaaa;
  opacity: 1;
}
.closeX {
  position: absolute;
  top: 10px;
  right: 12px;
  width: 26px;
  height: 26px;
}
.closeX *,
.closeX *:before,
.closeX *:after,
.closeX,
.closeX:before,
.closeX:after {
  cursor: pointer;
}
.closeX:before,
.closeX:after {
  position: absolute;
  left: 12px;
  top: 4px;
  content: ' ';
  height: 20px;
  width: 2px;
  background-color: #333333;
}
.closeX:before {
  transform: rotate(45deg);
}
.closeX:after {
  transform: rotate(-45deg);
}
.mdl-shadow--2dp {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.mdl-shadow--3dp {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
}
.whiteBackground {
  background: white;
}
.green-button-outer {
  font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-weight: normal;
  display: inline-block;
  font-size: 16px;
  background: white;
  border: 2px solid #29AA00;
  height: 36px;
  line-height: 32px;
  padding: 0 20px;
  border-radius: 18px;
}
.green-button-outer *,
.green-button-outer *:before,
.green-button-outer *:after,
.green-button-outer,
.green-button-outer:before,
.green-button-outer:after {
  cursor: pointer;
}
html,
body {
  background: url("/images/background_confetti_2.png") repeat;
  height: 100%;
  display: flex;
  flex-direction: column;
}
html > *,
body > * {
  flex-shrink: 0;
  flex-grow: 0;
}
h1 {
  font-family: 'Raleway', 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-weight: normal;
  color: black;
  font-size: 32px;
  line-height: 42px;
  margin: 10px 0;
}
h1.xl {
  font-family: 'Raleway', 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-weight: normal;
  color: black;
  font-size: 38px;
  line-height: 50px;
  margin: 0;
}
@media (min-width: 960px) {
  h1.xl {
    font-size: 44px;
    line-height: 58px;
  }
}
h2 {
  font-family: 'Raleway', 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-weight: normal;
  color: black;
  font-size: 24px;
  line-height: 38px;
  margin: 0 0 20px;
}
@media (min-width: 960px) {
  h2 {
    font-size: 26px;
    line-height: 40px;
  }
}
p {
  font-family: 'Raleway', 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-weight: normal;
  color: #333333;
  font-size: 16px;
  line-height: 26px;
  margin: 0 0 10px;
}
#nav-bar {
  position: fixed;
  z-index: 1;
  top: 0;
  display: flex;
  width: 100%;
  background: white;
  padding: 15px 30px;
  align-items: center;
  justify-content: space-between;
}
#nav-bar .downloadMac > img {
  width: 22px;
  height: 22px;
  display: inline-block;
  vertical-align: text-bottom;
  margin: 0;
}
#nav-bar > * {
  display: flex;
  align-items: center;
}
#nav-bar > * a {
  margin: 0 0 0 20px;
  transition: opacity 300ms;
}
#nav-bar > * a:hover {
  opacity: 0.6;
}
.content-block {
  background: white;
  padding: 30px;
}
#intro {
  margin-top: 66px;
  display: flex;
  flex-direction: column;
}
@media (min-width: 780px) {
  #intro {
    flex-direction: row;
  }
}
#intro .green-button {
  margin: 20px 0 0;
}
#intro-left {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
@media (min-width: 780px) {
  #intro-left {
    width: 50%;
    text-align: left;
  }
}
#intro-left .content-block {
  width: fit-content;
}
#intro-left .content-block h1.inner-content {
  max-width: 400px;
  margin: 10px auto;
}
#intro-left .content-block p {
  max-width: 500px;
}
@media (min-width: 780px) {
  #intro-left .content-block p {
    max-width: 340px;
  }
}
#intro-right {
  width: 100%;
  height: fit-content;
  align-self: center;
  overflow: hidden;
}
@media (min-width: 780px) {
  #intro-right {
    width: 50%;
  }
}
#intro-right > img {
  max-width: 130%;
}
@media (min-width: 780px) {
  #intro-right > img {
    max-width: 170%;
  }
}
@media (min-width: 960px) {
  #intro-right > img {
    max-width: 150%;
  }
}
#intro-right > img.exactFit {
  width: 100%;
}
#content {
  width: 100%;
  margin: 0 auto;
}
@media (min-width: 780px) {
  #content {
    width: 780px;
  }
}
@media (min-width: 960px) {
  #content {
    width: 900px;
  }
}
@media (min-width: 1200px) {
  #content {
    width: 1060px;
  }
}
#content .section {
  position: relative;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
#app-links {
  display: flex;
  align-items: center;
  justify-content: center;
}
#app-links img {
  margin: 20px 5px;
  height: 48px;
  width: auto;
}
#data-protection {
  color: white;
  background: #0095FF;
  padding: 80px 40px;
  margin-bottom: 90px;
}
@media (min-width: 960px) {
  #data-protection {
    padding: 100px 40px;
  }
}
#data-protection p {
  font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-weight: normal;
  color: white;
  max-width: 600px;
  font-size: 16px;
  line-height: 26px;
  margin: 0;
}
@media (min-width: 960px) {
  #data-protection p {
    max-width: 670px;
    font-size: 18px;
  }
}
#data-protection h1 {
  font-family: 'Raleway', 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-weight: normal;
  color: white;
  max-width: 300px;
  margin: 20px 0;
}
#footer {
  font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-weight: 300;
  background: white;
  display: flex;
  width: 100%;
  margin-top: auto;
  padding: 20px 30px;
  align-items: center;
  font-size: 12px;
}
@media (min-width: 520px) {
  #footer {
    font-size: 14px;
  }
}
@media (min-width: 780px) {
  #footer {
    font-size: 18px;
  }
}
#footer div,
#footer a {
  color: #333333;
  margin-right: 10px;
}
@media (min-width: 780px) {
  #footer div,
  #footer a {
    margin-right: 30px;
  }
}
#footer a {
  display: block;
  text-decoration: none;
  transition: opacity 300ms;
}
#footer a:hover {
  opacity: 0.6;
}
#footer a#contact {
  margin-left: auto;
  margin-right: 0;
}
