@import "common";

@min-xs: ~"(min-width: 520px)";
@min-s: ~"(min-width: 780px)";
@min-m: ~"(min-width: 960px)";
@min-l: ~"(min-width: 1200px)";

.whiteBackground {
  background: white;
}

.green-button-outer {
  .font-default();
  .pointer();
  display: inline-block;
  font-size: 16px;
  background: white;
  border: 2px solid @green;
  height: 36px;
  line-height: 32px;
  padding: 0 20px;
  border-radius: 18px;
}

html, body {
  background: url("/images/background_confetti_2.png") repeat;
  height: 100%;
  display: flex;
  flex-direction: column;

  > * {
    flex-shrink: 0;
    flex-grow: 0;
  }
}

h1 {
  .font-title();
  color: black;
  font-size: 32px;
  line-height: 42px;
  margin: 10px 0;

  &.xl {
    .font-title();
    color: black;
    font-size: 38px;
    line-height: 50px;
    margin: 0;

    @media @min-m {
      font-size: 44px;
      line-height: 58px;
    }
  }
}

h2 {
  .font-title();
  color: black;
  font-size: 24px;
  line-height: 38px;
  margin: 0 0 20px;

  @media @min-m {
    font-size: 26px;
    line-height: 40px;
  }
}

p {
  .font-title();
  color: @color-primary;
  font-size: 16px;
  line-height: 26px;
  margin: 0 0 10px;
}

#nav-bar {
  position: fixed;
  z-index: 1;
  top: 0;
  display: flex;
  width: 100%;
  background: white;
  padding: 15px 30px;
  align-items: center;
  justify-content: space-between;

  .downloadMac > img {
    width: 22px;
    height: 22px;
    display: inline-block;
    vertical-align: text-bottom;
    margin: 0;
  }

  > * {
    display: flex;
    align-items: center;

    a {
      margin: 0 0 0 20px;
      transition: opacity 300ms;

      &:hover {
        opacity: 0.6;
      }
    }
  }
}

.content-block {
  background: white;
  padding: 30px;
}

#intro {
  margin-top: 66px;
  display: flex;
  flex-direction: column;

  @media @min-s {
    flex-direction: row;
  }

  .green-button {
    margin: 20px 0 0;
  }

  &-left {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media @min-s {
      width: 50%;
      text-align: left;
    }

    .content-block {
      width: fit-content;

      h1.inner-content {
        max-width: 400px;
        margin: 10px auto;
      }

      p {
        max-width: 500px;

        @media @min-s {
          max-width: 340px;
        }
      }
    }
  }

  &-right {
    width: 100%;
    height: fit-content;
    align-self: center;
    overflow: hidden;

    @media @min-s {
      width: 50%;
    }

    > img {
      max-width: 130%;
      @media @min-s {
        max-width: 170%;
      }
      @media @min-m {
        max-width: 150%;
      }

      &.exactFit {
        width: 100%;
      }
    }
  }
}

#content {
  width: 100%;
  margin: 0 auto;

  @media @min-s {
    width: 780px;
  }

  @media @min-m {
    width: 900px;
  }

  @media @min-l {
    width: 1060px;
  }

  .section {
    position: relative;
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

#app-links {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin: 20px 5px;
    height: 48px;
    width: auto;
  }
}

#data-protection {
  color: white;
  background: @blue;
  padding: 80px 40px;
  margin-bottom: 90px;

  @media @min-m {
    padding: 100px 40px;
  }

  p {
    .font-default();
    color: white;
    max-width: 600px;
    font-size: 16px;
    line-height: 26px;
    margin: 0;

    @media @min-m {
      max-width: 670px;
      font-size: 18px;
    }
  }

  h1 {
    .font-title();
    color: white;
    max-width: 300px;
    margin: 20px 0;
  }
}

#footer {
  .font-light();
  background: white;
  display: flex;
  width: 100%;
  margin-top: auto;
  padding: 20px 30px;
  align-items: center;
  font-size: 12px;

  @media @min-xs {
    font-size: 14px;
  }

  @media @min-s {
    font-size: 18px;
  }

  div, a {
    color: @color-primary;
    margin-right: 10px;

    @media @min-s {
      margin-right: 30px;
    }
  }

  a {
    display: block;
    text-decoration: none;
    transition: opacity 300ms;

    &:hover {
      opacity: 0.6;
    }
  }

  a#contact {
    margin-left: auto;
    margin-right: 0;
  }
}
